<template>
	<PaymentSummaryCard :payment-lines="filteredPaymentLines" />
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
	productIsEligibleForDownPayment,
	productIsEligibleForSwap,
	getValidatedSubscriptionPrice,
} from "~/helpers/ecommerce/hardwareOrderHelper";
import { formatPrice } from "~/helpers/formatting/price";

export default defineNuxtComponent({
	name: "PaymentSummary",

	props: {
		showSubItems: {
			type: Boolean,
		},
		shoppingCart: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		...mapState("shoppingCart", ["loading"]),
		...mapGetters("shoppingCart", ["visibleCartItems", "visibleSubscriptionsInCart", "isVatExempt", "subscriptions"]),

		paymentLines() {
			return [
				{
					id: "SUM",
					name: "Sum",
					monthlyPaymentPrice: this.totalMonthly,
					payNowPrice: this.totalOneTime,
					hasDiscount: false,
					show: false,
				},
				{
					id: "DISCOUNT",
					name: "Rabatter",
					monthlyPaymentPrice: "--",
					payNowPrice: "--",
					hasDiscount: false,
					show: false,
				},
				{
					id: "TOTAL_TO_PAY",
					name: "Å Betale",
					monthlyPaymentPrice: this.totalMonthly,
					payNowPrice: this.totalOneTime,
					hasDiscount: false,
					show: true,
				},
			];
		},

		filteredPaymentLines() {
			return this.paymentLines.filter((line) => line.show);
		},
		validatedSubscriptionPrices() {
			return getValidatedSubscriptionPrice(this.subscriptions, this.visibleSubscriptionsInCart);
		},
		totalMonthly() {
			const getCorrectMonthlyPrice = (item) => {
				const isExistingSubscription = (item) => item?.type === "subscription" && item?.metadata?.existingSubscription;
				if (item?.metadata?.category === "Smartklokker") {
					return item?.metadata?.downPayment && item.metadata?.installmentsInMonths
						? Math.round((item?.metadata?.salePrice || item?.metadata?.price) / item?.metadata?.installmentsInMonths)
						: 0;
				} else if (productIsEligibleForSwap(item) || productIsEligibleForDownPayment(item)) {
					return item?.metadata?.swap ? item?.metadata?.saleRecurringPrice || item?.metadata?.recurringPrice : 0;
				} else {
					return isExistingSubscription(item)
						? 0
						: /* Check if item is a subscription and if it has a validated price
                              only return a discounted price in cart if it is validated
                          */
							item.type === "subscription"
							? this.validatedSubscriptionPrices?.originalPrice
								? // DiscountedPrice and price are the same for subscriptions that are not discounted
									this.validatedSubscriptionPrices?.discountedPrice || this.validatedSubscriptionPrices?.originalPrice
								: // Return recurringPrice if subscription && !validated
									item.metadata?.recurringPrice || 0
							: // Return "best price" if not subscription
								item.metadata?.saleRecurringPrice || item.metadata?.recurringPrice || 0;
				}
			};
			return this.formatPrice(this.visibleCartItems, getCorrectMonthlyPrice);
		},
		totalOneTime() {
			const getCorrectOneTimePrice = (item) => {
				if (productIsEligibleForSwap(item) || productIsEligibleForDownPayment(item)) {
					if (this.isVatExempt) {
						return item.metadata?.downPayment && item.metadata?.installmentsInMonths
							? 0
							: item?.metadata?.swap
								? item?.metadata?.memberPrice?.prices?.withoutVat?.memberPrice &&
									item?.subItems?.some((subItem) => subItem.type === "subscription")
									? item?.metadata?.memberPrice?.prices?.withoutVat?.memberPrice
									: item.metadata.salePriceWithoutVat || item.metadata.priceWithoutVat
								: 0;
					} else {
						return item.metadata?.downPayment && item.metadata?.installmentsInMonths
							? 0
							: !item?.metadata?.swap
								? item?.metadata?.memberPrice?.prices?.withVat?.memberPrice &&
									item?.subItems?.some((subItem) => subItem.type === "subscription")
									? item?.metadata?.memberPrice?.prices?.withVat?.memberPrice
									: item.metadata.salePrice || item.metadata.price
								: 0;
					}
				} else {
					if (this.isVatExempt) {
						return item?.metadata?.memberPrice?.prices?.withoutVat?.memberPrice &&
							item?.subItems?.some((subItem) => subItem.type === "subscription")
							? item?.metadata?.memberPrice?.prices?.withoutVat?.memberPrice
							: item.metadata.salePriceWithoutVat || item.metadata.priceWithoutVat || item.data.price || 0;
					} else {
						return item?.metadata?.memberPrice?.prices?.withVat?.memberPrice &&
							item?.subItems?.some((subItem) => subItem.type === "subscription")
							? item?.metadata?.memberPrice?.prices?.withVat?.memberPrice
							: item.metadata.salePrice || item.metadata.price || item.data.price || 0;
					}
				}
			};
			return this.formatPrice(this.visibleCartItems, getCorrectOneTimePrice);
		},
	},

	methods: {
		formatPrice(items, useFunction = (it) => it) {
			return formatPrice.oneTime(
				items
					.filter((item) => item?.type !== "old-swap-device")
					.map((item) => {
						console.log(item);
						if (this.showSubItems) {
							return [item, ...(item?.subItems ?? {})];
						} else {
							return [item];
						}
					})
					.flat()
					.map(useFunction)
					.reduce((a, b) => a + b, 0),
				{ round: true },
			);
		},
	},
});
</script>
