<template>
	<TnCard
		data-cy-payment-summary-card
		border
		class="payment-summary-container"
	>
		<PaymentSummaryLine>
			<template #line-right>
				<TnParagraph
					bold
					size="xs"
				>
					Månedspris
				</TnParagraph>

				<TnParagraph
					bold
					size="xs"
				>
					Å betale nå
				</TnParagraph>
			</template>
		</PaymentSummaryLine>

		<PaymentSummaryLine
			v-for="(line, index) in paymentLines"
			:key="line.id"
			:data-cy="line.id"
			:has-boarder="hasBoarder(index)"
		>
			<template #line-left>
				<TnParagraph :bold="isLast(index)">
					{{ line.name }}
				</TnParagraph>
			</template>

			<template #line-right>
				<TnParagraph
					:data-cy="`${line.id}-monthly-price`"
					:bold="isLast(index)"
					:class="{ 'price-discount': line.hasDiscount }"
				>
					{{ line.monthlyPaymentPrice }}
				</TnParagraph>

				<TnParagraph
					:data-cy="`${line.id}-pay-now-price`"
					:bold="isLast(index)"
				>
					{{ line.payNowPrice }}
				</TnParagraph>
			</template>
		</PaymentSummaryLine>
	</TnCard>
</template>

<script>
export default defineNuxtComponent({
	name: "PaymentSummaryCard",

	props: {
		paymentLines: {
			type: Array,
			required: true,
			validator: (lines) => {
				return lines.every((line) => {
					return (
						Object.hasOwn(line, "id") &&
						Object.hasOwn(line, "name") &&
						Object.hasOwn(line, "monthlyPaymentPrice") &&
						Object.hasOwn(line, "payNowPrice") &&
						Object.hasOwn(line, "hasDiscount")
					);
				});
			},
			// 	this needs to be an array with this object:
			// 	[{
			// 		id: "Something",
			// 		name: "Something",
			// 		monthlyPaymentPrice: "0,-",
			// 		payNowPrice: "0,-",
			// 		hasDiscount: false,
			// 	}];
		},
	},

	methods: {
		hasBoarder(index) {
			return index !== 0;
		},

		isLast(index) {
			return index === this.paymentLines.length - 1;
		},
	},
});
</script>

<style scoped lang="scss">
.card.payment-summary-container {
	display: grid;
	grid-template-rows: auto auto auto auto;
	flex-wrap: nowrap;
	padding: $spacing-m !important;
}

.price-discount {
	text-decoration: line-through;
	color: $color-neutrals-400-tint;
}
</style>
