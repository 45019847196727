<template>
	<div
		:class="[
			'payment-summary-line',
			{
				'payment-line-border-top': hasBoarder,
			},
		]"
	>
		<div class="payment-summary-line-left">
			<slot name="line-left" />
		</div>

		<div class="payment-summary-line-right">
			<slot name="line-right" />
		</div>
	</div>
</template>

<script>
export default defineNuxtComponent({
	name: "PaymentSummaryLine",

	props: {
		hasBoarder: {
			type: Boolean,
			default: false,
		},
	},
});
</script>

<style scoped lang="scss">
.payment-summary-line {
	padding-bottom: $spacing-xs;
	display: grid;
	grid-template-columns: 50% 50%;

	.payment-summary-line-right {
		display: grid;
		grid-template-columns: 50% 50%;
		text-align: right;
	}
}

.payment-line-border-top {
	border-top: 1px solid $color-neutrals-100-tint;
	padding-top: $spacing-xs;
}
</style>
